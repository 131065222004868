<ng-container *ngIf="!showLoader; else loaderTemplate">
<div class="listing-container w100 h100 d-flex flex-column align-items-center">

    <span class="heading font-md-32px text-center">Transfer your AURUM Reward Points</span>

      <ng-container *ngIf="providers.length">
        <div class="listing row w100">
        <div class="col-lg-3 col-6 d-flex listing-card"
          [ngStyle]="{'order': provider.attributes.position ?? 50}"
          *ngFor="let provider of providers"
        >
          <div
            class="merchant-detail w100 d-flex flex-column align-items-center pointer"
            (click)="navigateToProviderDetails(provider?.id)"
          >
            <img
              class="merchant-logo img-fluid"
              [src]="provider?.attributes?.logo || appConstants.FALLBACK_IMAGE"
            />
            <span class="merchant-title font-md-18px">{{ provider?.attributes?.name }}</span>
            <span class="merchant-burn-ratio w100 ellipsis font-md-16px"
              >{{ provider?.attributes?.conversion_ratio }} RP = 1
              {{ provider?.attributes?.currency_name }}</span
            >
            <div class="flex-fill"></div>
            <span class="transfer-cta">Transfer</span>
          </div>
        </div>
      </div>
      </ng-container>
      <ng-container *ngIf="!providers.length">
        <span class="font-12px font-md-16px text-center pad-16-y text-white">No partner active at the moment, Please try again later</span>
      </ng-container>
</div>
</ng-container>
<div *ngIf="!showLoader" class="how-it-works-panel d-flex flex-row flex-wrap justify-content-center align-items-center">
  <div class="title text-brand w100 text-center">
    Make the most out of your AURUM Rewards
  </div>
  <div class="d-flex justify-content-start align-items-center align-items-md-start flex-flow flex-wrap">
    <div *ngFor="let item of howItWorksData; let i = index;" class="how-it-works-data no-padding-md d-flex mar-16-b w-100 col-lg-4 col-md-4
    flex-md-row flex-md-wrap">
      <div class="img-container mar-md-20-b"  [ngSwitch]="i">
        <ng-container  *ngSwitchCase="0">
          <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M39.9998 70.0002L35.1665 65.6668C29.5554 60.6113 24.9165 56.2502 21.2498 52.5835C17.5832 48.9168 14.6665 45.6252 12.4998 42.7085C10.3332 39.7918 8.81928 37.1113 7.95817 34.6668C7.09706 32.2224 6.6665 29.7224 6.6665 27.1668C6.6665 21.9446 8.4165 17.5835 11.9165 14.0835C15.4165 10.5835 19.7776 8.8335 24.9998 8.8335C27.8887 8.8335 30.6387 9.44461 33.2498 10.6668C35.8609 11.8891 38.1109 13.6113 39.9998 15.8335C41.8887 13.6113 44.1387 11.8891 46.7498 10.6668C49.3609 9.44461 52.1109 8.8335 54.9998 8.8335C60.2221 8.8335 64.5832 10.5835 68.0832 14.0835C71.5832 17.5835 73.3332 21.9446 73.3332 27.1668C73.3332 29.7224 72.9026 32.2224 72.0415 34.6668C71.1804 37.1113 69.6665 39.7918 67.4998 42.7085C65.3332 45.6252 62.4165 48.9168 58.7498 52.5835C55.0832 56.2502 50.4443 60.6113 44.8332 65.6668L39.9998 70.0002ZM39.9998 61.0002C45.3332 56.2224 49.7221 52.1252 53.1665 48.7085C56.6109 45.2918 59.3332 42.3196 61.3332 39.7918C63.3332 37.2641 64.7221 35.0141 65.4998 33.0418C66.2776 31.0696 66.6665 29.1113 66.6665 27.1668C66.6665 23.8335 65.5554 21.0557 63.3332 18.8335C61.1109 16.6113 58.3332 15.5002 54.9998 15.5002C52.3887 15.5002 49.9721 16.2363 47.7498 17.7085C45.5276 19.1807 43.9998 21.0557 43.1665 23.3335H36.8332C35.9998 21.0557 34.4721 19.1807 32.2498 17.7085C30.0276 16.2363 27.6109 15.5002 24.9998 15.5002C21.6665 15.5002 18.8887 16.6113 16.6665 18.8335C14.4443 21.0557 13.3332 23.8335 13.3332 27.1668C13.3332 29.1113 13.7221 31.0696 14.4998 33.0418C15.2776 35.0141 16.6665 37.2641 18.6665 39.7918C20.6665 42.3196 23.3887 45.2918 26.8332 48.7085C30.2776 52.1252 34.6665 56.2224 39.9998 61.0002Z" stroke="url(#paint0_linear_1183_438)" stroke-width="2"/>
            <defs>
            <linearGradient id="paint0_linear_1183_438" x1="39.9998" y1="8.8335" x2="39.9998" y2="70.0002" gradientUnits="userSpaceOnUse">
            <stop stop-color="#D7C36B"/>
            <stop offset="1" stop-color="#9F7E2E"/>
            </linearGradient>
            </defs>
            </svg>

        </ng-container>
        <ng-container  *ngSwitchCase="1">
          <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M36.6665 56.6668H23.3332C18.7221 56.6668 14.7915 55.0418 11.5415 51.7918C8.2915 48.5418 6.6665 44.6113 6.6665 40.0002C6.6665 35.3891 8.2915 31.4585 11.5415 28.2085C14.7915 24.9585 18.7221 23.3335 23.3332 23.3335H36.6665V30.0002H23.3332C20.5554 30.0002 18.1943 30.9724 16.2498 32.9168C14.3054 34.8613 13.3332 37.2224 13.3332 40.0002C13.3332 42.7779 14.3054 45.1391 16.2498 47.0835C18.1943 49.0279 20.5554 50.0002 23.3332 50.0002H36.6665V56.6668ZM26.6665 43.3335V36.6668H53.3332V43.3335H26.6665ZM43.3332 56.6668V50.0002H56.6665C59.4443 50.0002 61.8054 49.0279 63.7498 47.0835C65.6943 45.1391 66.6665 42.7779 66.6665 40.0002C66.6665 37.2224 65.6943 34.8613 63.7498 32.9168C61.8054 30.9724 59.4443 30.0002 56.6665 30.0002H43.3332V23.3335H56.6665C61.2776 23.3335 65.2082 24.9585 68.4582 28.2085C71.7082 31.4585 73.3332 35.3891 73.3332 40.0002C73.3332 44.6113 71.7082 48.5418 68.4582 51.7918C65.2082 55.0418 61.2776 56.6668 56.6665 56.6668H43.3332Z" stroke="url(#paint0_linear_1183_444)" stroke-width="2"/>
            <defs>
            <linearGradient id="paint0_linear_1183_444" x1="39.9998" y1="23.3335" x2="39.9998" y2="56.6668" gradientUnits="userSpaceOnUse">
            <stop stop-color="#D7C36B"/>
            <stop offset="1" stop-color="#9F7E2E"/>
            </linearGradient>
            </defs>
            </svg>

        </ng-container>
        <ng-container  *ngSwitchCase="2">
          <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3333 26.6668C13.3333 20.1668 15.5972 14.6529 20.125 10.1252C24.6528 5.59738 30.1667 3.33349 36.6667 3.33349C42.6111 3.33349 47.7917 5.29183 52.2083 9.2085C56.625 13.1252 59.1667 18.0279 59.8333 23.9168L65.3333 18.6668L70 23.3335L56.6667 36.6668L43.3333 23.3335L48.0833 18.6668L53 23.5835C52.2222 19.6391 50.3056 16.3891 47.25 13.8335C44.1944 11.2779 40.6667 10.0002 36.6667 10.0002C32.0556 10.0002 28.125 11.6252 24.875 14.8752C21.625 18.1252 20 22.0557 20 26.6668L20 36.6668L13.3333 36.6668L13.3333 26.6668ZM43.3333 43.3335L66.6667 43.3335L66.6667 73.3335L43.3333 73.3335L43.3333 43.3335ZM13.3333 43.3335L36.6667 43.3335L36.6667 73.3335L13.3333 73.3335L13.3333 43.3335ZM20 50.0002L20 66.6668L30 66.6668L30 50.0002L20 50.0002Z" stroke="url(#paint0_linear_1183_450)" stroke-width="2"/>
            <defs>
            <linearGradient id="paint0_linear_1183_450" x1="70" y1="38.3335" x2="13.3333" y2="38.3335" gradientUnits="userSpaceOnUse">
            <stop stop-color="#D7C36B"/>
            <stop offset="1" stop-color="#9F7E2E"/>
            </linearGradient>
            </defs>
            </svg>

        </ng-container>

      </div>
      <div class="data">
        <div class="sub-title font-16px text-brand">
          {{item.name}}
        </div>
        <div class="description text-white font-14px font-md-16px">
          {{item.description}}
        </div>
      </div>
    </div>
  </div>

</div>

<ng-template #loaderTemplate>
  <div class="w-100 h-100 d-flex justify-content-center align-items-center" style="min-height: 400px;">
  <img src="assets/spinner.svg" />
  </div>
</ng-template>
